<!-- 添加采样时间计划（封装组件） jzy-->
<template>
  <!-- 添加采样计划弹窗 -->
  <el-dialog
    title="批量添加采样计划"
    :visible="visible"
    width="80%"
    :show-close="true"
    @close="selfClose"
  >
    <div class="condition-filtrate-box">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-form-item class="formitem" label="人群分类：">
          <el-select
            class="select-width"
            value-key="code"
            collapse-tags
            @change="selectchange($event)"
            v-model="selectArr"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in typepopSelect"
              :key="item.id"
              :label="item.ruleName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="入住时间：">
          <el-date-picker
            v-model="queryForm.timetime"
            size="medium"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>

        <el-form-item label="房间号码：">
          <el-input
            size="medium"
            v-model="queryForm.roomNo"
            placeholder="房间号"
          />
        </el-form-item>

        <el-form-item label="居民信息：">
          <el-input
            size="medium"
            v-model="queryForm.searchValue"
            placeholder="姓名/证件号码/电话号码"
          />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      ref="multipleTable"
      :data="tableData"
      max-height="300"
      @selection-change="handleSelectionChange"
      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="item in columns"
        :key="item.key"
        :property="item.key"
        :label="item.title"
        :width="item.width"
      />
    </el-table>

    <div class="bottomArrange">
      <div class="timePicker">
        <el-form
          ref="collectDateForm"
          label-width="100px"
          :model="collectDateForm"
        >
          <el-form-item
            :rules="[
              {
                required: true,
                message: '采样时间不能为空',
              },
            ]"
            label="采样时间："
            prop="date"
            :required="true"
          >
            <el-date-picker
              style="width: 100%"
              type="date"
              v-model="collectDateForm.date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择时间"
              :picker-options="pickerOptions"
            />
          </el-form-item>
        </el-form>
      </div>

      <div>
        <span class="text">已选中{{ multipleSelection.length }}项目</span>
      </div>

      <div class="pagenation">
        <el-pagination
          :page-sizes="[10, 200, 300, 400]"
          layout="total, sizes,prev, pager, next,jumper"
          :total="total"
          :current-page="pageNumber"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button @click="handleModalCancel">取 消</el-button>
      <el-button type="primary" @click="handleModalConfirm" :loading="loading"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import {
  residentlistt,
  alreadyChenckIn,
} from "../../../api/QuarantineRegistration/quarantineRegistrationpop";
import { _debounce } from "@/utils/utils.js"; //引入按钮防抖
import moment from "moment";
export default {
  computed: {
    ...mapState({
      typepopSelect: (state) => state.user.typepopSelect, // 人群分类
    }),
  },
  data() {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000;
        }, // 禁用日期
        // selectableRange: `${Date().split(" ")[4]} - 23:59:59`, // 打开默认当前时间
      },
      columns: [
        { key: "name", title: "姓名", witdh: 150 },
        { key: "cardNo", title: "证件号码", witdh: 200 },
        { key: "crowdTypeName", title: "人群分类", witdh: 150 },
        { key: "regTime", title: "入住时间", witdh: 200 },
        { key: "startTime", title: "开始隔离时间", witdh: 150 },
        { key: "roomNo", title: "房间号码", witdh: 200 },
      ],
      collectDate: "", //选择采样计划时间
      tableData: [],
      total: -0,
      pageNumber: 1,
      pageSize: 10,
      ids: [],
      selectArr: [],
      // selectString:"",
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      queryForm: {
        searchValue: "",
        roomNo: "", //房间号
        crowdTypeIds: "", //人群分类名称(多选)
        status: "1", //默认状态为 “隔离中” 的人员列表
        timetime: [], //查询条件之入住时间
      },
      multipleSelection: [], //选中的人的数组
      collectDateForm: {
        date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  props: {
    visible: Boolean,
    loading: Boolean,
  },
  created() {
    // console.log(this.loading,'打印loading点击进来的')
    // console.log(this.visible,'打印visible点击进来的')
    // this.collectDateForm.date = moment().format("YYYY-MM-DD");
    console.log("init");
    this.collectDateForm.date = moment().format("YYYY-MM-DD");
  },
  mounted() {
    this.handleSearchList();
  },
  methods: {
    // 获取已入住状态为隔离中的人员列表
    async handleSearchList() {
      let params = {
        hospitalId: this.userdoctor.hospitalId,
        orgCode: this.userdoctor.orgCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      let { searchValue, crowdTypeIds, roomNo, timetime } = this.queryForm;
      if (searchValue) params.searchValue = searchValue;
      if (crowdTypeIds) params.crowdTypeIds = crowdTypeIds;
      if (roomNo) params.roomNo = roomNo;

      if (timetime && timetime.length == 2) {
        params.regTimeMin = timetime[0];
        params.regTimeMax = timetime[1];
      }

      let result = await alreadyChenckIn({ params });
      // console.log(result, "获取已入住人员列表");
      let { code, data, msg } = result.data;
      if (code == 200) {
        let { totalRow, list } = data;
        this.tableData = list; //整个列表的数据
        this.total = totalRow;
      } else {
        this.$message.error(msg);
      }
    },
    // 选择主要人群分类（多选数组）
    selectchange(event) {
      let arr = [];
      for (let item1 in event) {
        for (let item2 in this.typepopSelect) {
          if (event[item1] == this.typepopSelect[item2].id) {
            arr.push(this.typepopSelect[item2]);
          }
        }
      }
      let arrCrowdId = arr.map((item) => {
        return item.id;
      });
      if (arrCrowdId.length > 0) {
        this.queryForm.crowdTypeIds = arrCrowdId.join(",");
        console.log(
          this.queryForm.crowdTypeIds,
          "打印this.queryForm.crowdTypeIds"
        );
      } else {
        this.queryForm.crowdTypeIds = "";
      }
    },

    // 搜索
    handleSearch() {
      this.pageNumber = 1;
      this.handleSearchList();
    },
    // 分页
    handleSizeChange(val) {
      this.pageSize = val;
      this.handleSearchList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageNumber = val;
      this.handleSearchList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.ids = this.multipleSelection.map((item) => {
        return item.id;
      });
      // console.log(this.ids);
      //   this.multipleSelection = val;
    },
    // 确定btn
    handleModalConfirm() {
      this.$refs.collectDateForm.validate((isValid) => {
        console.log(isValid, "打印出校验的状态");
        if (isValid) {
          const params = {
            hospitalId: this.userdoctor.hospitalId,
            orgCode: this.userdoctor.orgCode,
            pageNumber: this.pageNumber,
            pageSize: this.pageSize,
            ids: this.ids,
            collectDate: this.collectDateForm.date,
          };
          //   console.log(params, "params model");
          this.loading = true;
          this.$emit("onConfirm", params);
        }
      });
    },
    // 取消btn
    handleModalCancel() {
      this.$refs.multipleTable.clearSelection();
      this.reset();
      this.$emit("onCancel", {});
    },
    // 弹窗自带关闭按钮事件，右上角的x
    selfClose() {
      this.$refs.multipleTable.clearSelection();
      this.reset();
      this.$emit("onCancel");
    },
    // reset清除参数
    reset() {
      this.multipleSelection = [];
      this.collectDateForm.date = "";
      this.queryForm = {};
      this.selectArr = [];
    },
  },
};
</script>

<style lang='scss' scoped>
.bottomArrange {
  overflow: hidden;
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-wrap: nowrap;
  justify-content: space-between;
  .timePicker {
    // background-color: aquamarine;
    width: 30%;
  }
  .text {
    // background-color: aquamarine;
    width: 10%;
    font-size: medium;
    font-weight: bold;
  }
  .pagenation {
    text-align: right;
    width: 50%;
    // background-color: aquamarine;
  }
}

.el-select {
  /deep/.el-select__tags {
    // 解决多个tag卡片溢出隐藏问题
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    .el-tag {
      // 注意这个inline-block 对卡片溢出隐藏很重要
      display: inline-block;

      // 解决单个tag文字过长溢出隐藏问题
      .el-select__tags-text {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .el-icon-close {
        top: -6px;
        right: -4px;
      }
    }
  }
}
</style>